import { useState, useEffect } from "react";
import { useSigner } from "wagmi";
import { useAccount } from "wagmi";
import BackgroundOverlay from "./../components/background-overlay";

export default function Page(props) {
    const { data: signer } = useSigner();
    const [balance, setBalance] = useState(0);
    const [amount, setAmount] = useState(0);
    const [pkg, setPkg] = useState({ p: 0, r: 0 });
    const [approved, setApproved] = useState(0);
    const [error, setError] = useState("");
    const [stack, setStack] = useState(0);
    const [allowance, setAllowance] = useState(0);

    const [reward, setReward] = useState(0);

    const { address, isConnecting, isDisconnected } = useAccount();

    const getBalance = async () => {
        props.bc.getBalance(address, (bal) => {
            setBalance(bal);
        });
    };

    useEffect(() => {
        getBalance();
    }, []);

    const stakeOf = () => {
        props.bc.stakeOf(address, (stack) => {
            setStack(stack);
        });
    };

    useEffect(() => {
        stakeOf();
    }, [amount]);

    const getAllowance = () => {
        props.bc.getAllowance(address, (_allowance) => {
            setAllowance(_allowance);
        });
    };

    const rewardOf = () => {
        props.bc.rewardOf(address, (_reward) => {
            setReward(_reward);
        });
    };

    useEffect(() => {
        rewardOf();
    }, [reward]);

    useEffect(() => {
        getAllowance();
    }, [approved]);

    const approveTokens = () => {
        if (amount == 0) {
            setError("Cannot approve zero amount");
            setTimeout(() => {
                setError("");
            }, 3000);
            return;
        }
        props.bc.approve(signer, amount, (resp) => {
            if (resp.result == "success") {
                setApproved(amount);
                setAmount(amount);
                getAllowance();
            } else {
                setError(resp.error);
            }
        });
    };

    const claimReward = () => {
        console.log(reward);
        if (reward == 0) {
            setError("No reward is available");
            setTimeout(() => {
                setError("");
            }, 3000);
            return;
        }
        props.bc.claimReward(signer, (resp) => {
            if (resp.result == "success") {
                stakeOf();
                rewardOf();
            } else {
                setError(resp.error);
            }
        });
    };

    const removeAllMyStakes = () => {
        // console.log(reward)
        // if(reward==0)
        // {
        //   setError("No reward is available");
        //   setTimeout(()=> { setError("");  }, 3000);
        //   return;
        // }
        props.bc.removeAllMyStakes(signer, (resp) => {
            if (resp.result == "success") {
                rewardOf();
            } else {
                setError(resp.error);
            }
        });
    };

    const createStake = () => {
        if (amount == 0) {
            setError("Cannot stack zero amount");
            setTimeout(() => {
                setError("");
            }, 3000);
            return;
        }

        // if(pkg.p==0)
        // {
        //   setError("Select a Package");
        //   setTimeout(()=> { setError("");  }, 3000);
        //   return;
        // }

        props.bc.createStake(signer, amount, 0, (resp) => {
            if (resp.success) {
                setAmount(0);
                setApproved(0);
            } else {
                setError("Error: " + resp.error);
            }
            setTimeout(() => {
                setError("");
            }, 3000);
        });
    };

    const removeSomeStake = () => {
        console.log(amount);
        if (amount == 0) {
            setError("Cannot remove 0");
            setTimeout(() => {
                setError("");
            }, 3000);
            return;
        }
        props.bc.removeSomeStake(signer, amount, (resp) => {
            console.log(resp);
            if (resp.success) {
                setAmount(0);
                setApproved(0);
            } else {
                setError("Error: " + resp.error);
            }
            setTimeout(() => {
                setError("");
            }, 3000);
        });
    };

    return (
        <>
            <div
                className="container"
                style={{ zIndex: 12, position: "relative" }}
            >
                <div>
                    <h1 className="fs-9 b ">Staking Crypto Heros</h1>
                </div>

                {reward && reward > 0 && (
                    <section className="my-5 mb-5">
                        <div className="flex">
                            <div>
                                <h2 className="fs-5 cen">
                                    Reward Cliamable:{" "}
                                    <span className="tcs py-2">{reward}</span>
                                </h2>
                                <p className="cen">
                                    <button
                                        onClick={claimReward}
                                        class="btn  py-2 mx-3 w-200"
                                    >
                                        Claim
                                    </button>
                                </p>
                            </div>
                        </div>
                    </section>
                )}

                <section className="my-5 mb-5">
                    <div className="mt-3 stacking-packages">
                        <p className="cen fs-5">
                            Minimum staking period to earn reward is 24 hours
                        </p>
                        <p className="cen fs-5">
                            Yearly APY: 3778.37% 
                        </p>
                    </div>

                    <div className="stacking-amount mt-4">
                        <p className="right fs-5 available-balance">
                            <span className="">Balance : </span>
                            <span className="b ml-3 tcs ">
                                {balance} $HEROES
                            </span>
                        </p>

                        <p className="right fs-5 available-balance">
                            <span className="">Already Staked : </span>
                            <span className="b ml-3 tcs ">{stack} $HEROES</span>
                        </p>

                        <p className="right fs-5 available-balance">
                            <span className="">Approved For Staking : </span>
                            <span className="b ml-3 tcs ">
                                {allowance} $HEROES
                            </span>
                        </p>
                        <p className="right fs-5 available-balance">
                            <span className="">Rewards : </span>
                            <span className="b ml-3 tcs ">
                                {reward} $HEROES
                            </span>
                        </p>

                        <input
                            onChange={(e) => {
                                setAmount(e.target.value);
                            }}
                            value={amount}
                            type="number"
                            id="token_amount"
                        />
                    </div>

                    <p className="error fs-3">{error}</p>

                    <div className="mt-5 cen">
                        {(approved < amount || approved === 0) && (
                            <button
                                onClick={approveTokens}
                                className="btn  py-2 mx-3 w-200"
                            >
                                Approve
                            </button>
                        )}

                        {(approved > 0 || allowance > 0) && (
                            <button
                                onClick={createStake}
                                className="btn py-2 mx-3 w-200"
                            >
                                Stake
                            </button>
                        )}
                    </div>
                </section>

                {stack && stack > 0 && (
                    <section className="my-5 mb-5">
                        <div className="flex">
                            <div>
                                <p className="cen">
                                    <strong className="b u tcs">
                                        Disclaimer:{" "}
                                    </strong>{" "}
                                    5% Fee will be taken on unstaking before 24
                                    Hours.
                                </p>

                                <p className="cen">
                                    <button
                                        onClick={removeSomeStake}
                                        class="btn  py-2 mx-3 w-300"
                                    >
                                        Remove {amount} $Hero{" "}
                                    </button>
                                </p>
                            </div>
                        </div>
                    </section>
                )}
            </div>
        </>
    );
}
