import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {useAccount, useConnect, useDisconnect} from 'wagmi';


function Header(props)
{

    const { address, isConnected } = useAccount();
    const { disconnect } = useDisconnect();
    const navigate = useNavigate();

    let isLogin = sessionStorage.getItem("isLogin");

    isLogin = (isLogin=="yes");

    async function doDisconnect()
    {
        const disconnect_btn_postion = document.getElementById('disconnect_btn').style.top;
        if(disconnect_btn_postion =='-120px')
        {
          document.getElementById('disconnect_btn').style.top = "47px";
        }
        else
        {
          document.getElementById('disconnect_btn').style.top = "-120px";
        }
        await disconnect();
    }

    const[connectWallet, setConnectWallet] = useState("Connect Wallet");

    useEffect(() => 
    {
        if(isConnected)
        {
            let addr = address.substring(address.length-4, address.length);
            setConnectWallet("Connected: 0x.."+addr);
        }
    }, [isConnected]);


    return(
        
    <header>

        <div className="flex-lg container">
            <div style={{flexGrow:2}} className=" sm-cen">
                    <img src="/logo.png" alt='loog' className="main-logo" />
            </div>

            <div style={{flexGrow:2}} className="nav-container">
            <nav className='container fs-4 cen'>
                <Link to="https://cryptoheroesnet-4855faf89c52e69809cab4f.webflow.io/" className='mx-3'>Crypto Heros</Link>
                <Link to="/" className='mx-3'>Home</Link>
            </nav>
            </div>

            <div className="flex connect-button">
                <div className="sm-cen py-3 w-full">
                    <button className='btn sm-my-3' onClick={()=>{ if(!isConnected) { navigate('/connectors')}}} 
                            id='header_connect_btn'>{connectWallet}
                    </button>
                </div>
            </div>
        </div>



    </header>


    )
}

export default Header
