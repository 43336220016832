import BackgroundOverlay from "./background-overlay";

function Footer()
{

    return(
    <footer>
        <div className="container py-3">
        <h3 className='cen fs-9'>
        <span className='gradient-text'>CONTACT US</span>
        </h3>

        <div style={{flexGrow:2}} className=" cen">
             <a href="https://cryptoheroesnet-4855faf89c52e69809cab4f.webflow.io/">
                <img src="/logo.png" alt='logo' style={{width:"300px"}} />
            </a>
        </div>  


        <div className="container">
                <p className="b fs-4 u cen">
                    <a style={{color:"white"}} href="https://team@cryptoheroes.vip">
                    team@cryptoheroes.vip
                    </a>
                </p>
        </div>  

        <div className="cen">
            <a href="https://twitter.com/#">
                <img className='sm-icons' src="/images/twitter.png" />
            </a>
            <a href="https://t.me/#">
                <img className='sm-icons' src="/images/telegram.png" />
            </a>
                         
        </div>

      


        <p className="cen" style={{marginTop:"80px"}}>Copyright © 2023 Crypto Heroes. All rights reserved!</p>

        </div>
    </footer>
    )

}

export default Footer;