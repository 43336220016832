// eslint-disable-next-line

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { configureChains, createClient, WagmiConfig } from "wagmi";
import {
    mainnet,
    bsc,
    goerli,
    bscTestnet,
    polygon,
    polygonMumbai,
} from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import { InjectedConnector } from "@wagmi/core";
import { ethers } from "ethers";

var ABI = [
    "function getState() public view returns(uint256, uint256, uint256)",
    "function rewardOf(address _stakeholder) public view returns(uint256)",
    "function stakeOf(address _stakeholder) public view returns(uint256)",
    "function approve(address spender, uint256 amount) external returns (bool)",
    "function allowance(address owner, address spender) external view returns (uint256)",
    "function createStake(uint256 _stake, uint256 _stakingPackage) external",
    "function removeSomeStake(uint256 _stake) public",
];

const chains = [mainnet, goerli, bsc, bscTestnet, polygon, polygonMumbai];
const { provider, webSocketProvider } = configureChains(chains, [
    publicProvider(),
]);

const injected = new InjectedConnector({
    options: { qrcode: true, name: "Connect Wallet" },
});

const client = createClient({
    autoConnect: true,
    provider,
    webSocketProvider,
    connectors: [injected],
});

class Blockchain {
    constructor() {
        this.isLogin = false;
        this.totalStackers = 0;
        this.stackedAmount = 0;
        this.rewardIssued = 0;
        this.address = "";
        this.contract = "0x97E5E6191277EacF59EC46574E1C5b4c7016e601";
        this.stackingContract = "0xc2149fa98107f3453B706Fa0D73AB2646f6eFDc8";
        this.abi = ABI;
        this.owner = "";
        this.rpc =
            "https://mainnet.infura.io/v3/aa65dbb313104a608ab5b55aba0e306b";
        this.provider = new ethers.providers.JsonRpcProvider(this.rpc);
    }

    ajax = async (path, method, postData, callBack) => {
        const fetchOptions = {
            method: method,
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(postData),
        };
        const apiEndPoint = this.apiUrl + path;
        const response = await fetch(apiEndPoint, fetchOptions);

        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.status}`);
        }

        const data = await response.json();

        if (data.status == "success") {
            callBack(data);
        } else {
            alert("failed");
        }
    };

    approve = async (signer, amount, callback) => {
        let amountInWei = ethers.utils.parseUnits(amount.toString(), "ether");
        const writeContract = new ethers.Contract(
            this.contract,
            this.abi,
            signer
        );
        const txResponse = await writeContract.approve(
            this.stackingContract,
            amountInWei
        );
        try {
            const receipt = await txResponse.wait();
            callback({ result: "success" });
        } catch (error) {
            callback({ result: "success", error: error.reason });
        }
    };

    claimReward = async (signer, callback) => {
        const ABI = ["function claimReward() public"];
        const writeContract = new ethers.Contract(
            this.stackingContract,
            ABI,
            signer
        );
        const txResponse = await writeContract.claimReward();
        try {
            const receipt = await txResponse.wait();
            callback({ result: "success" });
        } catch (error) {
            callback({ result: "error", error: error.reason });
        }
    };

    removeAllMyStakes = async (signer, callback) => {
        const ABI = ["function removeAllMyStakes() public"];
        const writeContract = new ethers.Contract(
            this.stackingContract,
            ABI,
            signer
        );
        const txResponse = await writeContract.removeAllMyStakes();
        try {
            const receipt = await txResponse.wait();
            callback({ result: "success" });
        } catch (error) {
            callback({ result: "error", error: error.reason });
        }
    };

    getBalance = async (address, callback) => {
        const ABI = [
            "function balanceOf(address _addr) external view returns (uint256)",
        ];
        const contract = new ethers.Contract(this.contract, ABI, this.provider);
        let state = (await contract.balanceOf(address)).toString();
        let balance = ethers.utils.formatEther(state);
        callback(balance);
    };

    rewardOf = async (address, callback) => {
        const ABI = [
            "function rewardOf(address _stakeholder) public view returns(uint256)",
        ];
        const contract = new ethers.Contract(
            this.stackingContract,
            ABI,
            this.provider
        );
        let state = (await contract.rewardOf(address)).toString();
        let balance = ethers.utils.formatEther(state);
        callback(balance);
    };

    createStake = async (signer, amount, pkg, callback) => {
        let amountInWei = ethers.utils.parseUnits(amount.toString(), "ether");
        const writeContract = new ethers.Contract(
            this.stackingContract,
            this.abi,
            signer
        );
        try {
            const txResponse = await writeContract.createStake(
                amountInWei,
                pkg
            );
            const receipt = await txResponse.wait();
            console.log(receipt);
            callback({ success: true });
        } catch (error) {
            callback({ success: false, error: error.reason });
        }
    };

    removeSomeStake = async (signer, amount, callback) => {
      const ABI = ["function removeSomeStake(uint256 _stake) public "];

        let amountInWei = ethers.utils.parseUnits(amount.toString(), "ether");
        const writeContract = new ethers.Contract(
            this.stackingContract,
            ABI,
            signer
        );
        try {
          console.log(amountInWei)
            const txResponse = await writeContract.removeSomeStake(amountInWei);
            const receipt = await txResponse.wait();
            console.log(receipt);
            callback({ success: true });
        } catch (error) {
            callback({ success: false, error: error.reason });
        }
    };

    getState = async (callback) => {
        const contract = new ethers.Contract(
            this.stackingContract,
            this.abi,
            this.provider
        );
        const state = await contract.getState();
        const totalStackers = state[0].toString();
        this.totalStackers = totalStackers;
        let stackedAmount = ethers.utils.formatEther(state[1].toString());
        this.stackedAmount = stackedAmount;
        let rewardIssued = ethers.utils.formatEther(state[2].toString());
        this.rewardIssued = rewardIssued;
        callback({ totalStackers, stackedAmount, rewardIssued });
    };

    stakeOf = async (address, callback) => {
        const contract = new ethers.Contract(
            this.stackingContract,
            this.abi,
            this.provider
        );
        let stake = await contract.stakeOf(address);
        stake = ethers.utils.formatEther(stake.toString());
        callback(stake);
    };

    getAllowance = async (address, callback) => {
        const contract = new ethers.Contract(
            this.contract,
            this.abi,
            this.provider
        );
        let _allowance = await contract.allowance(
            address,
            this.stackingContract
        );
        _allowance = ethers.utils.formatEther(_allowance.toString());
        callback(_allowance);
    };
} //end of blockchain

const bc = new Blockchain();

if (sessionStorage.getItem("isLogin") == "yes") {
    bc.isLogin = true;
    bc.user = JSON.parse(sessionStorage.getItem("user"));
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <WagmiConfig client={client}>
            <App bc={bc} />
        </WagmiConfig>
    </React.StrictMode>
);
