import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Header from './components/header';
import Footer from './components/footer';

import Home from "./pages/Home";
import Admin from "./pages/admin";
import Stacking from "./pages/stacking";
import Connectors from "./pages/connectors";

function App(props) {
  
  return (
    <BrowserRouter>
      <Header bc={props.bc} />
        <Routes>
          <Route path="/"  element={<Stacking bc={props.bc} />} />
          <Route path="/connectors" element={<Connectors bc={props.bc} />} />
        </Routes>
      <Footer bc={props.bc} />
    </BrowserRouter>
  );
}


export default  App;
